define("discourse/plugins/discourse-authentication-validations/discourse/initializers/user-field-validation", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _object, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "user-field-validation",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.33.0", api => {
        api.addCustomUserFieldValidationCallback(userField => {
          if (userField.field.has_custom_validation && userField.field.value_validation_regex && userField.value) {
            if (!new RegExp(userField.field.value_validation_regex).test(userField.value)) {
              return _object.default.create({
                failed: true,
                reason: (0, _discourseI18n.i18n)("discourse_authentication_validations.value_validation_error_message", {
                  user_field_name: userField.field.name
                }),
                element: userField.field.element
              });
            }
          }
        });
      });
    }
  };
});